<template>
  <div class="bg">
    <TopVue></TopVue>

    <div class="form_boxs">
      <div class="title" style="text-align: center; margin-top: -50px;margin-bottom: 10px;">
                    <img src="../assets/menu/m4.png" alt="" class="title_img">
                    <!-- 党员信息表 -->
            </div>
      <div class="hello form_box" v-if="show">
      
        <div class="back" @click='show = false'><img src="../assets/back.png" class="back1" alt="">返回</div>

        <div>
          <el-card class="box-card">
            <!-- 用户列表区域 -->
            <el-table :data="userList1" :stripe="true" :border="true" style="width: 100%;height:60vh">
              <el-table-column prop="party_member_id" label="编号"></el-table-column>
              <el-table-column prop="partymemberapi.username" label="姓名"></el-table-column>
              <el-table-column prop="partymemberapi.partygroupapi.group_name" label="所属组织"></el-table-column>
              <!-- <el-table-column prop="tell" label="手机号码"></el-table-column>
              <el-table-column prop="shengri" label="出生日期"></el-table-column>
              <el-table-column prop="xueli" label="学历"></el-table-column>
              <el-table-column prop="step" label="阶段"></el-table-column> -->
              <el-table-column prop="payment_time" label="缴纳时间"></el-table-column>
              <!-- <el-table-column proppayment_timetime2" label="转正时间"></el-table-column> -->
              <el-table-column prop="amount" label="缴纳金额"></el-table-column>

            </el-table>
            <!-- 分页区域 -->
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination> -->
          </el-card>


        </div>


      </div>
      <div class="form_box hello" v-else @scroll="handleScroll">
                <div class="form_boxss">
                    <!-- 列表内容 -->
                    <div class="form_boxss1" v-for="(item, index) in userList" :key="index" @click="xiangqing(index)">
                        <img :src="item.partymemberapi.avatar_text" class="form_box_img" alt="">
                        <div class="form_box_text">
                            <div class="form_box_text_name">
                                姓名：{{ item.partymemberapi.username }}
                            </div>
                            <div class="form_box_text_name">
                                职务：{{ item.partymemberapi.partypostapi?item.partymemberapi.partypostapi.job_title:"暂无"}}
                            </div>
                        </div>
                    </div>

                    <!-- 加载更多 -->
                    <div v-if="isLoadingMore" class="loading-more">加载中...</div>
                </div>

            </div>

    </div>
  </div>
</template>

<script>
import TopVue from '../components/Top.vue'
import { dangfeilist } from '../request/api'
export default {
    components: {
        TopVue
    },
    data() {

        return {
            show: false,
            queryInfo: {
                // 查询参数
                query: '',
                // 当前的页码数
                pagenum: 1,
                // 每页显示多少条数据
                pagesize: 10
            },
            // 获取的用户列表
            //   userList: [],
            // 总数
            total: 0, userList1: [],
            userList: [],
            // currentPage: 1,
            users: [
                // ... 用户数据，例如 [ { id: 1, name: '...', email: '...' }, ... ]
            ],
            cout: 0,
            // pageSize: 10,
            currentPage1: 5,
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4,
            currentPage: 1, // 当前页数
            pageSize:100, // 每页条数
            isLoadingMore: false, // 是否正在加载更多
            hasMore: true, // 是否还有更多数据

        }
    },

    computed: {
        // 计算当前页的用户
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.users.slice(start, end);
        },
        // 计算总页数
        totalPages() {
            return Math.ceil(this.users.length / this.pageSize);
        }

    },
    created() {
        this.getUserList()
    },
    methods: {

        xiangqing(index) {
            this.cout = index
            this.userList1 = []
            this.userList1 = this.userList1.concat(this.userList[this.cout])
            this.show = true
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },

        async getUserList() {
            await dangfeilist({
                limit: this.pageSize, //每页展示条数
                page: this.currentPage,  //页码
                
            }).then(res => {
                console.log('res', res)
                if (res.data) {

                    var data = []
                    data = res.data.fee_list
                    if (this.currentPage == 1) {
                        this.userList = data
                    }
                    else {
                        this.userList=this.userList.concat(data)
                    }



                }

            })
            // this.userList = res.data.users
            // this.total = 2
            // console.log(res)
        },
        // 监听 pageSize 改变的事件
        handleSizeChange(newSize) {
            //  将监听接受到的每页显示多少条的数据 newSzie 赋值给 pagesize
            this.queryInfo.pagesize = newSize
            //  修改完以后，重新发起请求获取一次数据
            this.getUserList()
        },
        // 监听 页码值  改变的事件
        handleCurrentChange() {
            //  将监听接受到的页码值的数据 newPage 赋值给 pagenum
            this.queryInfo.pagenum = this.queryInfo.pagenum++
            //  修改完以后，重新发起请求获取一次数据
            this.getUserList()
        },
        // 加载数据的方法
        loadData() {
            if (!this.hasMore) return;

            this.isLoadingMore = true;
            // 模拟异步加载数据
            setTimeout(() => {
                this.currentPage++;
                this.getUserList()
                this.isLoadingMore = false;
            }, 1000);
        },
        // 处理滚动事件
        handleScroll(event) {
            const container = event.target;
            console.log('event', event)
            if (container.scrollHeight - container.scrollTop <= container.clientHeight) {
                this.loadData();
            }
        }
    }
}

</script>

<style scoped>
.back1 {
    width: 40px;
    height: 40px;

}

.back {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.hello {
    height: 80vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
    position: relative;
    /* 开启垂直滚动条 */
}

.loading-more {
    text-align: center;
    position: absolute;
    bottom: 0;
}

.bg {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/bg1.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: fixed;
}

.form_boxs {
    padding: 0 50px;


}

.title {
    text-align: center;
    margin: 0 auto;
}

.title,
.title1 {
    font-size: 25px;
}

.title_img {
    width: auto;
    height: 50px;
}

.form_box {
    text-align: center;
    border-radius: 10px;
    padding: 20px;

    background-color: rgba(255, 255, 255, 0.5);
}

.form_boxss {
    width: 100%;
    opacity: 1;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(8, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20rpx;
}

.form_boxss1 {
    /* width:14%; */
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 20px; */
}

.form_box_img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
}
</style>
