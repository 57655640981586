// 首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from '@/request/request' 

//党员、优秀党员列表
export function dangyuanlist(data) {
  return request({
   method:'POST',
   url:'/api/index/index',
   data: data
  })
}
export function fazhanlist(data) {
  return request({
   method:'POST',
   url:'/api/develop/index',
   data: data
  })
}
export function dangfeilist(data) {
  return request({
   method:'POST',
   url:'/api/index/partyFee',
   data: data
  })
}
export function zuzhilist(data) {
  return request({
   method:'POST',
   url:'/api/index/tissue',
   data: data
  })
}
export function pagelist(data) {
  return request({
   method:'POST',
   url:'/api/index/page',
   data: data
  })
}







